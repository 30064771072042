import React from "react"
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'

export default () => {
	if (typeof window === "undefined") {
    return <p>Server render</p>
  }
	return (
		<Splide options={{
			type: `loop`,
			autoplay: true,
			interval: 8000,
			breakpoints: {
				1500: {
					padding: `1rem`
				}
			}
		}}>
			<SplideSlide>
				<blockquote>
					<p>I seriously cannot rave about this lady enough. Her consistent reassurance, guidance and expertise is beyond measure. If you're considering having microblading done then please look no further. I came on recommendation and wouldn't think twice about sending anyone here. I am so, so, so extremely happy with my eyebrows - thank you so much Angela.</p>
					<footer>
					<i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>
					</footer>
				</blockquote>
			</SplideSlide>
			<SplideSlide>
				<blockquote>
					<p>Do you want an expert who makes the whole experience professional, relaxing and with excellent results? Then you have come to the right place! I can guarantee Angela will go above and beyond to make your time and money worthwhile. 100% recommend - love my eyebrows.</p>
					<footer>
					<i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>
					</footer>
				</blockquote>
			</SplideSlide>
			<SplideSlide>
				<blockquote>
					<p>I can't thank Angela enough. She talked me through my options for my eyebrows after I had started to lose them to alopecia and gave me a makeup tutorial on how to draw them on. But not only that she has given me the confidence to leave the house without feeling conscious, and for that I am truly grateful.</p>
					<footer>
					<i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>
					</footer>
				</blockquote>
			</SplideSlide>
			<SplideSlide>
				<blockquote>
					<p>I had my eyebrows done by Angela last week and she was amazing, really professional and friendly. I've been having HD brows for about a year at another salon and I will not be returning after visiting Angela - really happy with the shape and colour. She's even put me on a regrowth programme which was never offered to me before. Will definitely be back.</p>
					<footer>
					<i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>
					</footer>
				</blockquote>
			</SplideSlide>
			<SplideSlide>
				<blockquote>
					<p>I'm a newish customer at Birds of Beauty Parlour for HD brows and will definitely be staying a loyal customer. After years of trying different salons I can honestly say this is the best place. My eyebrows have improved massively and look great even between appointments with the advice given. Angela makes me feel comfortable, at ease, knowing my brows are going to look fab within half an hour to 45 minutes. I would 100% recommend the salon if you haven't found 'the one' yet.</p>
					<footer>
					<i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>
					</footer>
				</blockquote>
			</SplideSlide>
			<SplideSlide>
				<blockquote>
					<p>Went to see Angela this morning having never had any lashes or HD brows done before. I was feeling a little apprehensive but I was reassured straight away and felt so relaxed. I enjoyed every moment and absolutely loved the results, I looked so different. Thank you so much Angela.</p>
					<footer>
					<i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>
					</footer>
				</blockquote>
			</SplideSlide>
			<SplideSlide>
				<blockquote>
					<p>Love, love, love my HD brows and LVL lashes. Very professional, warm and friendly beauty parlour - would definitely be back. Thank you. Had many bad experiences with eyebrows until meeting Angela. I wouldn't let anyone else touch my eyebrows now. Her passion for what she does truly shines through and she really takes care and effort to explain everything whilst creating a masterpiece of your brows. Couldn't recommend her enough.</p>
					<footer>
					<i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>
					</footer>
				</blockquote>
			</SplideSlide>
			<SplideSlide>
				<blockquote>
					<p>Just wanted to say a big thank you to Angela for making this post menopausal woman feel happy with her eyebrows again. Lovely environment and Angela is very reassuring and professional. Great experience.</p>
					<footer>
					<i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>
					</footer>
				</blockquote>
			</SplideSlide>
			<SplideSlide>
				<blockquote>
					<p>It can be a worry with eyebrows knowing where to go and who to trust to do a good job. Angela is a brow expert and it shows in her treatments. Natural looking, attention to detail and a personally tailored service - I always recommend Birds of Beauty to friends. I wouldn't go anywhere else.</p>
					<footer>
					<i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>
					</footer>
				</blockquote>
			</SplideSlide>
			<SplideSlide>
				<blockquote>
					<p>I have been going to Angela for brows and lashes for 4 years. She has changed my brows from being misshaped and patchy to full and natural shaped. Everywhere I go I get compliments on how good my brows look, I wouldn't go anywhere else now.</p>
					<footer>
					<i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>
					</footer>
				</blockquote>
			</SplideSlide>
		</Splide>
	)
}