import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Card from "../components/Card"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from '../components/BackgroundImage'
import Testimonials from "../components/Testimonials"
import animationObserver from "../helpers/animationObserver"

export default () => {
  useEffect(() => {
    animationObserver('.feature .text, .card, .award');
  }, []);
  return (
    <Layout>
      <SEO pageTitle="Brows. Lashes. Nutrition." />
      <BackgroundImage bgImagePath="backgrounds/header.jpg">
        <header className="hero homepage">
          <div className="container">
            <div className="focus">
              <ul className="straplines">
                <li className="fade-in one">Brows.</li>
                <li className="fade-in two">Lashes.</li>
                <li className="fade-in three">Nutrition.</li>
              </ul>
              <div className="subhead fade-in four">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas deserunt enim soluta nobis explicabo, illo, ab adipisci libero, commodi voluptatum optio beatae ea in animi architecto recusandae eaque alias perferendis.</div>
            </div>
          </div>
        </header>
      </BackgroundImage>
      <h1 className="sr-only">Home</h1>
      <section className="intro-text">
        <div className="container">
          Longer intro text about Birds of Beauty salon. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mauris pharetra et ultrices neque ornare aenean euismod elementum nisi. Cursus sit amet dictum sit.
        </div>
      </section>
      <BackgroundImage bgImagePath="backgrounds/header.jpg" tint="var(--grey-light-trans)">
        <section className="feature text-right">
          <BackgroundImage className="image" bgImagePath="backgrounds/header.jpg"></BackgroundImage>
            <div className="container">
              <div className="text">
                <h2>Treatments</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas deserunt enim soluta nobis explicabo, illo, ab adipisci libero, commodi voluptatum optio beatae ea in animi architecto recusandae eaque alias perferendis.</p>
                <Link className="button info" to="/treatments/">View all treatments</Link>
                <Link className="button cta" to="/book/">Book a treatment</Link>
              </div>
            </div>
        </section>
      </BackgroundImage>
      <BackgroundImage bgImagePath="backgrounds/header.jpg" tint="var(--grey-dark-trans)">
        <section className="feature text-left">
          <BackgroundImage className="image" bgImagePath="backgrounds/header.jpg"></BackgroundImage>
            <div className="container">
              <div className="text">
                <h2>Shop</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas deserunt enim soluta nobis explicabo, illo, ab adipisci libero, commodi voluptatum optio beatae ea in animi architecto recusandae eaque alias perferendis.</p>
                <Link className="button cta" to="/shop/">View shop</Link>
              </div>
            </div>
        </section>
      </BackgroundImage>
      <section className="feature-cards grey-bg">
        <div className="container wide">
          <div className="row">
            <div className="col">
              <Card page="/gallery" imagePath="cards/cardimg.jpg" title="Gallery" className="one">
                Intro text about gallery. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mauris pharetra et ultrices neque ornare aenean euismod elementum nisi. Cursus sit amet dictum sit.
              </Card>
            </div>
            <div className="col">
              <Card page="/blog" imagePath="cards/cardimg.jpg" title="Blog" className="two">
                Intro text about blog. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mauris pharetra et ultrices neque ornare aenean euismod elementum nisi. Cursus sit amet dictum sit.
              </Card>
            </div>
            <div className="col">
              <Card page="https://www.robovoucher.com/buy/list/UK4GCS-2231" imagePath="cards/cardimg.jpg" title="Gift vouchers" className="three">
                Intro text about gift vouchers. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mauris pharetra et ultrices neque ornare aenean euismod elementum nisi. Cursus sit amet dictum sit.
              </Card>
            </div>
            <div className="col">
              <Card page="https://www.bbc.co.uk" imagePath="cards/cardimg.jpg" title="Memberships" className="four">
                Intro text about gallery. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mauris pharetra et ultrices neque ornare aenean euismod elementum nisi. Cursus sit amet dictum sit.
              </Card>
            </div>
          </div>
        </div>
      </section>
      <section className="awards">
        <div className="container">
          <h2>Awards</h2>
          <div className="row">
            <div className="col">
              <div className="award one">
                <StaticImage src="../images/general/BritishHairBeautyAwards.png" height={150} alt="British Hair and Beauty Awards logo"/>
                <h3>Winner</h3>
                <p className="title">Brow Specialist of the Year</p>
                <p>British Hair and Beauty Awards 2020</p>
              </div>
            </div>
            <div className="col">
              <div className="award two">
                <StaticImage src="../images/general/EnglishNailsBrowsLashesAwards.png" height={150} alt="English Nails Brows Lashes Awards 2020 logo"/>
                <h3>Finalist</h3>
                <p>English Nails Brows Lashes Awards 2020</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BackgroundImage bgImagePath="backgrounds/header.jpg" tint="rgba(112, 112, 112, 0.75)">
        <section className="testimonials">
          <div className="container">
            <h2>See what our customers think...</h2>
            <Testimonials />
            <a href="https://www.google.com/search?q=birds+of+beauty+parlour&oq=birds+of+beauty+parlour&aqs=chrome.0.69i59j69i60l2.3040j0j7&sourceid=chrome&ie=UTF-8#lrd=0x4879c7803de12bc3:0x3895511df4e062fa,1,,," className="button cta">Read more Google reviews</a>
          </div>
        </section>
      </BackgroundImage>
    </Layout>
  )
}
